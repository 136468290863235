<template>
  <!-- 四足机器人英文 -->
  <div class="product quadruped_en" v-if="elmentList.length">
    <div class="banner_video" :id="'elm' + elmentList[0].id">
      <video
        autoplay="autoplay"
        loop
        muted
        preload
        class="video"
        v-if="this.$store.state.pageset.pcshow"
        type="video/mp4"
        :src="elmentList[0].pcImg"
        :ref="'video' + elmentList[0].pcElementId"
      ></video>
      <video
        v-else
        autoplay
        muted
        playsinline
        loop
        x5-video-player-type="h5-page"
        x-webkit-airplay="true"
        webkit-playsinline="true"
        x5-video-player-fullscreen="false"
        class="video"
        type="video/mp4"
        :src="elmentList[0].phoneImg"
        :ref="'video' + elmentList[0].phoneElementId"
        :id="'videoOne'"
      ></video>
      <!-- <div class="video-div" id="mobileQu" v-else></div> -->
      <div class="img-con">
        <div class="title">
          <h1>{{ elmentList[0].descList[0].descName }}</h1>
        </div>
      </div>
      <div class="dec" v-animate="'queue-bottom'">
        <div class="dec-par">
          <div class="dec-par-item" v-for="index in 4" :key="index">
            <p class="dec-par-item-title">
              {{ elmentList[0].descList[index].descName }}
            </p>
            <p class="dec-par-item-dec">
              {{ elmentList[0].descList[index + 4].descName }}
            </p>
          </div>
        </div>
        <div>
          <el-button
            size="small"
            style="margin-right: 20px"
            class="btn-div-experience btn-div-order"
            plain
            @click="retention"
            >Order Now</el-button
          >
          <el-button
            size="small"
            class="btn-div-experience btn-div-test"
            plain
            @click="retention"
            >Register for Demo</el-button
          >
        </div>
        <div class="scroll-wrap">
          <div class="remark">
            The products appearing in the video on this page are for reference only. The appearance, configuration, model, and other details of the products may vary. Please refer to the actual local delivery for details.
          </div>
        </div>
      </div>
    </div>
    <div class="quadruped-content">
      <div class="content-one" :id="'elm' + elmentList[1].id">
        <img
          :src="elmentList[1].pcImg"
          v-if="this.$store.state.pageset.pcshow"
        />
        <img :src="elmentList[1].phoneImg" v-else />
        <div class="dec gradient" v-animate="'queue-bottom'">
          <div class="dec-left">
            <p class="p1">{{ elmentList[1].descList[0].descName }}</p>
            <p class="p1">{{ elmentList[1].descList[1].descName }}</p>
            <el-button
              v-if="this.$store.state.pageset.pcshow"
              size="small"
              class="btn-div-experience white-btn"
              plain
              @click="retention"
              >Order Now</el-button
            >
          </div>
          <div class="dec-right">
            <p class="p2">
              {{ elmentList[1].descList[2].descName }}
            </p>
          </div>
          <div class="phone-order" v-if="!this.$store.state.pageset.pcshow">
            <el-button
              size="small"
              class="btn-div-experience white-btn"
              plain
              @click="retention"
              >Order Now</el-button
            >
          </div>
        </div>
      </div>
      <div class="content-two" :id="'elm' + elmentList[2].id">
        <img
          :src="elmentList[2].pcImg"
          v-if="this.$store.state.pageset.pcshow"
        />
        <img :src="elmentList[2].phoneImg" v-else />
        <div class="dec">
          <div class="dec-left">
            <p class="p2">{{ elmentList[2].descList[0].descName }}</p>
            <p class="p1">{{ elmentList[2].descList[1].descName }}</p>
            <p class="p2">{{ elmentList[2].descList[2].descName }}</p>
            <el-button
              v-if="this.$store.state.pageset.pcshow"
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >Order Now</el-button
            >
          </div>
          <div class="dec-right"></div>
          <el-button
            v-if="!this.$store.state.pageset.pcshow"
            size="small"
            class="btn-div-experience black-btn"
            plain
            @click="retention"
            >Order Now</el-button
          >
        </div>
      </div>

      <!-- 电液复合驱动 -->
      <img :src="elmentList[3].pcImg" v-if="this.$store.state.pageset.pcshow" />
      <img :src="elmentList[3].phoneImg" v-else />
      <div class="content-one" :id="'elm' + elmentList[3].id">
        <div
          class="dec"
          v-animate="'queue-bottom'"
          style="background-color: white; color: black !important"
        >
          <div class="dec-left">
            <p class="p1">{{ elmentList[3].descList[0].descName }}</p>
            <el-button
              v-if="this.$store.state.pageset.pcshow"
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >Order Now</el-button
            >
          </div>
          <div class="dec-right">
            <p class="p2">
              {{ elmentList[3].descList[1].descName }}
            </p>
          </div>
          <div class="phone-order" v-if="!this.$store.state.pageset.pcshow">
            <el-button
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >Order Now</el-button
            >
          </div>
        </div>
      </div>
      <div class="content-wrapper" >
        <div class="mySwiper img-swiper swiper swiper-container">
          <div class="swiper-wrapper screen-swiper">
            <div class="swiper-slide" v-for="index in 5" :key="index" :id="'elm' + elmentList[index + 3].id">
              <!-- 滑动切换内容 -->
              <img
                :src="elmentList[index + 3].pcImg"
                v-if="$store.state.pageset.pcshow"
              />
              <img :src="elmentList[index + 3].phoneImg" v-else />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
        <div
          class="dec"
          v-animate="'queue-bottom'"
          style="background-color: white; color: black !important"
        >
          <div class="dec-left">
            <p class="p1">{{ elmentList[4].descList[0].descName }}</p>
            <el-button
              v-if="this.$store.state.pageset.pcshow"
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >Order Now</el-button
            >
          </div>
          <div class="dec-right">
            <p class="p2">
              {{ elmentList[4].descList[1].descName }}
            </p>
          </div>
          <div class="phone-order" v-if="!this.$store.state.pageset.pcshow">
            <el-button
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >Order Now</el-button
            >
          </div>
        </div>
      </div>
      <!-- 产品参数(四足参数单独样式) -->
      <div class="content-par-remark" :id="'elm' + elmentList[9].id">
        <img :src="elmentList[9].pcImg" v-if="$store.state.pageset.pcshow" />
        <img :src="elmentList[9].phoneImg" v-else />
        <div class="dec">
          <p class="p1">{{ elmentList[9].descList[0].descName }}</p>
          <p class="p1">{{ elmentList[9].descList[1].descName }}</p>
          <div class="dec-con" v-animate="'queue-bottom'">
            <div class="dec-con-left">
              <ul class="des-ui">
                <li v-for="index in 6" :key="index">
                  <p>{{ elmentList[9].descList[index + 1].descName }}</p>
                  <p>{{ elmentList[9].descList[index + 13].descName }}</p>
                </li>
              </ul>
            </div>
            <div class="dec-con-right">
              <ul class="des-ui">
                <li v-for="index in 6" :key="index">
                  <p>{{ elmentList[9].descList[index + 7].descName }}</p>
                  <p>{{ elmentList[9].descList[index + 19].descName }}</p>
                </li>
              </ul>
            </div>
          </div>
          <el-button
            v-animate="'queue-bottom'"
            size="small"
            class="btn-div-experience white-btn"
            plain
            @click="openPdf"
            >{{ elmentList[11].descList[0].descName }}</el-button
          >
        </div>
      </div>

      <!-- 产品订购(四足参数单独样式) -->
      <div class="content-par-order" :id="'elm' + elmentList[10].id">
        <div class="img" v-if="!this.$store.state.pageset.pcshow">
          <img :src="elmentList[10].phoneImg" />
        </div>
        <div class="dec" v-animate="'queue-bottom'">
          <p>{{ elmentList[10].descList[0].descName }}</p>
          <p>{{ elmentList[10].descList[1].descName }}</p>
          <div>
            <el-button
              size="small"
              style="background-color: black; color: #fff"
              class="btn-div-experience"
              plain
              @click="retention"
              >Order Now</el-button
            >
            <el-button
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >Register for Demo</el-button
            >
          </div>
        </div>
        <div class="img" v-if="this.$store.state.pageset.pcshow">
          <img :src="elmentList[10].pcImg" />
        </div>
      </div>
    </div>
  </div>
  <div v-else v-loading="true"></div>
</template>
<script>
import quadruped_en from "./quadruped_en.js";

export default quadruped_en;
</script>
<style scoped rel="stylesheet/stylus" lang="scss">
@import "~@/assets/style/common.scss";
@import "../quadruped.scss";
@import "./quadruped_en.scss";
@import "../../common.scss";

/deep/.swiper-pagination-bullet {
  background: rgb(153, 153, 153) !important;
  color: rgb(153, 153, 153);
}
/deep/.swiper-pagination-bullet-active {
  background: white !important;
  color: white;
}
</style>
