import Swiper from "swiper";
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcElementId: 241,
            //             phoneElementId: 252,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Quadruped Robot',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 2
            //                 }, {
            //                     descName: '100+',
            //                     sort: 3
            //                 }, {
            //                     descName: '50KG',
            //                     sort: 4
            //                 }, {
            //                     descName: 'Quadruped Explosion-proof Technology',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: 'Electro-hydraulic Composite Drive',
            //                     sort: 6
            //                 }, {
            //                     descName: 'Scenario Expansion',
            //                     sort: 7
            //                 }, {
            //                     descName: 'Payload Weight',
            //                     sort: 8
            //                 }, {
            //                     descName: 'Highly Control',
            //                     sort: 9
            //                 }],
            //             type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcElementId: 242,
            //             phoneElementId: 253,
            //             descList: [
            //                 {
            //                     descName: 'Quadruped Explosion-proof Technology',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex IIB T4 Gb',
            //                     sort: 2
            //                 },
            //                 {
            //                     descName: 'Adopting composite explosion-proof designs of positive pressure, intrinsic safety, flameproofness and rubber sealing, we have overcome the technical difficulties of explosion-proof technology for quadruped robots in China and broken through technological barriers. Compliant with national explosion-proof standards, quadruped robots are able to conduct safety inspections in explosion-proof areas such as petroleum, chemical, warehousing and ports. They have high adaptability to unknown and unstructured environments and can move on complex road surfaces such as stairs, steps, gaps, obstacles and narrow spaces, effectively reducing production safety risks.',
            //                     sort: 3
            //                 }],
            //             type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcElementId: 243,
            //             phoneElementId: 254,
            //             descList: [
            //                 {
            //                     descName: '',
            //                     sort: 1
            //                 }, {
            //                     descName: 'High strength, anti fatigue, good material and excellent structure, helping to ensure safe operation',
            //                     sort: 2
            //                 }, {
            //                     descName: 'The core components are made of titanium alloy, 7 series aluminum alloy, steel and other materials, combined with some forging blanks, carburizing/nitriding, shot peening and other processes to achieve advantages in high strength, lightweight, fatigue performance, and anti-static. They can remain stable performance under long-term use and repeated loads, meeting the needs of users for high performance and reliability.',
            //                     sort: 3
            //                 }],
            //             type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcElementId: 244,
            //             phoneElementId: 255,
            //             descList: [
            //                 {
            //                     descName: 'High-performance Electro-hydraulic Composite Drive Joint',
            //                     sort: 1
            //                 }, {
            //                     descName: 'We have conquered high-performance electro-hydraulic composite transmission technology and innovatively designed high integration and responsiveness for electro-hydraulic actuators. We have obtained advantages such as high power density, high impact resistance, high transmission efficiency and highly control performance. We have the functions of accidental ground impact and overload overflow protection, and can achieve precise position control with a repeated positioning accuracy of 5cm. The load weight of the robot can reach 50 kg, which can effectively replace manual labor to complete some complex and heavy handling tasks.',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcElementId:245,
            //             phoneElementId: 256,
            //             descList: [
            //                 {
            //                     descName: 'Expansion of Application Fields',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Explosion-proof Quadruped Robot can be applied in industries such as petroleum, chemical, natural gas and power industry, and can adapt to complex scenarios of various paved and unpaved road conditions. They can also expand into application fields such as forests, unmanned areas, coal mines, public relations and rescue, deserts, and rocky terrain.',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcElementId: 246,
            //             phoneElementId: 257,
            //             descList: [
            //                 {
            //                     descName: 'Expansion of Application Fields',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Explosion-proof Quadruped Robot can be applied in industries such as petroleum, chemical, natural gas and power industry, and can adapt to complex scenarios of various paved and unpaved road conditions. They can also expand into application fields such as forests, unmanned areas, coal mines, public relations and rescue, deserts, and rocky terrain.',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcElementId:247,
            //             phoneElementId: 258,
            //             descList: [
            //                 {
            //                     descName: 'Expansion of Application Fields',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Explosion-proof Quadruped Robot can be applied in industries such as petroleum, chemical, natural gas and power industry, and can adapt to complex scenarios of various paved and unpaved road conditions. They can also expand into application fields such as forests, unmanned areas, coal mines, public relations and rescue, deserts, and rocky terrain.',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 7
            //         },
            //         {
            //             pcElementId: 248,
            //             phoneElementId: 259,
            //             descList: [
            //                 {
            //                     descName: 'Expansion of Application Fields',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Explosion-proof Quadruped Robot can be applied in industries such as petroleum, chemical, natural gas and power industry, and can adapt to complex scenarios of various paved and unpaved road conditions. They can also expand into application fields such as forests, unmanned areas, coal mines, public relations and rescue, deserts, and rocky terrain.',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 8
            //         },
            //         {
            //             pcElementId: 249,
            //             phoneElementId: 260,
            //             descList: [
            //                 {
            //                     descName: 'Expansion of Application Fields',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Explosion-proof Quadruped Robot can be applied in industries such as petroleum, chemical, natural gas and power industry, and can adapt to complex scenarios of various paved and unpaved road conditions. They can also expand into application fields such as forests, unmanned areas, coal mines, public relations and rescue, deserts, and rocky terrain.',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 9
            //         },
            //         {
            //             pcElementId: 250,
            //             phoneElementId: 261,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Quadruped Robot',
            //                     sort: 0
            //                 },
            //                 {
            //                     descName: 'Specification',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Dimension (L×W×H)',
            //                     sort: 2
            //                 },
            //                 {
            //                     descName: 'Explosion-proof Grade',
            //                     sort: 3
            //                 },
            //                 {
            //                     descName: 'Payload Weight',
            //                     sort: 4
            //                 },
            //                 {
            //                     descName: 'Gradeability',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: 'Obstacle Surmounting Ability',
            //                     sort: 6
            //                 },
            //                 {
            //                     descName: 'Main Navigation Mode',
            //                     sort: 7
            //                 },
            //                 {
            //                     descName: 'Curb Weight',
            //                     sort: 8
            //                 },
            //                 {
            //                     descName: 'Protection Level',
            //                     sort: 9
            //                 }, {
            //                     descName: 'Walking Speed',
            //                     sort: 10
            //                 },
            //                 {
            //                     descName: 'Wading Depth',
            //                     sort: 11
            //                 },
            //                 {
            //                     descName: 'Battery Endurance',
            //                     sort: 12
            //                 },
            //                 {
            //                     descName: 'Repetitive Navigation Positioning Accuracy',
            //                     sort: 13
            //                 }, {
            //                     descName: '≤1250mm×660mm×1060mm',
            //                     sort: 14
            //                 },
            //                 {
            //                     descName: 'Ex IIB T4 Gb',
            //                     sort: 15
            //                 },
            //                 {
            //                     descName: '50kg',
            //                     sort: 16
            //                 },
            //                 {
            //                     descName: '≤30°',
            //                     sort: 17
            //                 },
            //                 {
            //                     descName: '200mm',
            //                     sort: 18
            //                 },
            //                 {
            //                     descName: 'Lidar + IMU',
            //                     sort: 19
            //                 },
            //                 {
            //                     descName: '≤150kg',
            //                     sort: 20
            //                 },
            //                 {
            //                     descName: 'IP56',
            //                     sort: 21
            //                 }, {
            //                     descName: '1.5m/s',
            //                     sort: 22
            //                 },
            //                 {
            //                     descName: '≤300mm',
            //                     sort: 23
            //                 },
            //                 {
            //                     descName: '2-3h',
            //                     sort: 24
            //                 },
            //                 {
            //                     descName: '≤±5cm',
            //                     sort: 25
            //                 }],
            //             type: 'image',
            //             sort: 10
            //         },
            //         {
            //             pcElementId:251,
            //             phoneElementId: 262,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Quadruped Robot',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Autonomous and unmanned inspection, do what human cannot',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 11
            //         },
            //         {
            //             pcElementId: 9,
            //             phoneElementId: 9,
            //             descList: [
            //                 {
            //                     descName: 'Product Manual',
            //                     sort: 1
            //                 }],
            //             type: 'image',
            //             sort: 12
            //         }
            //     ],

            elmentList: [],
            aboutswiper: null,
        }
    },
    mounted() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        // this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );

        document.documentElement.style.setProperty('--var-primary-color', '#fff');
       // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            setTimeout(() => {
                this.aboutSwiper();
                if (!this.$store.state.pageset.pcshow) {
                    let video = document.getElementById('videoOne');
                    video.play();
                  }
            }, 100);
        });
    },
    methods: {
        retention() {
            this.$router.push('/en/reservation');
        },
        openPdf() {
            window.open(this.elmentList[11].pcImg)
        },
        // 初始化轮播图
        aboutSwiper() {
            this.aboutswiper = new Swiper('.mySwiper', {
                pagination: {
                    el: '.swiper-pagination',
                    // clickable: true,
                },
                autoplay: true,
                speed: 2000,
                loop: true,
                // effect: 'fade'..//切换效果
                // fadeEffect: {
                //     crossFade: true ..//开启淡出。过渡时，原slide透明度从1->0（淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                //    ..//false  关闭淡出。过渡时，原slide透明度为1（不淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                // },
            });
        },
    }
}